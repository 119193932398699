
.root {
    border: 0 !important;
    height: 100%;
}

.content {
    background-color: var(--mantine-color-gray-0) !important;
    border-radius: var(--mantine-radius-md);
    font-weight: 500;
    height: 100%;
    /*
    border: 1px solid var(--mantine-color-gray-7) !important;
    */
    /*
    box-shadow: 0 4px 4px 1px rgba(0,0,0,0.11) !important
    */
}

.list {
    height: 15%
}

.panel {
    height: 85%;
    max-height: 85%;
}

.typographyStylesProvider {
    height: 100%;
}

.scrollAreaRoot {
    height: 180px;
}

.scrollAreaViewPort > div {
    height: 100%;
}

.textAreaRoot {
    height: 100%;
}

.textAreaWrapper {
    height: 100%;
}

.textAreaWrapper > textarea {
    height: 100% !important;
}

textarea.textAreaInput {
    min-height: 100%;
    height: 100% !important;
    background-color: var(--mantine-color-gray-0) !important;
    border-radius: var(--mantine-radius-md);
    border: 0;
}