

.title {
    --text-fz: var(--mantine-spacing-xl);
    --text-lh: var(--mantine-spacing-xl);
    font-weight: 500;
}

.body {
    width: 1038px;
    height: 658px;

    .header {
        background-color: var(--mantine-color-gray-5);
        padding: var(--mantine-spacing-sm);
        gap: var(--mantine-spacing-sm);

        .imageContainer {
            align-items: center;
            justify-content: center;
            min-width: 184px;
            min-height: 184px;

            .image {
                max-height: 184px;
                max-width: 184px;
                object-fit: cover;
            }
        }

        .data {
            width: 100%;
            gap: 0;
        }
    }


}