

.root {
    background-color: var(--mantine-color-gray-5);
    padding: var(--mantine-spacing-sm);
    gap: var(--mantine-spacing-sm);
    margin-top: var(--mantine-spacing-xs);
    border-radius: var(--mantine-radius-md);
}

.severity {
    width: 10px;
    border-radius: 10px;
}

.low {
    background-color: var(--mantine-color-yellow-0)
}