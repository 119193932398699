

.wrapper {
    --input-bg: var(--mantine-color-gray-0) !important;
    --input-disabled-bg: var(--mantine-color-gray-0) !important;
    color: var(--mantine-color-textBlack-4) !important;
    font-weight: 500;
    --input-radius: var(--mantine-radius-md) !important;
    --input-placeholder-color: var(--mantine-color-gray-1) !important;


    .textIcon {
        color: var(--mantine-color-green-2);
        --input-bd-focus: var(--mantine-color-green-2) !important
    }

    [data-disabled]{
        opacity: 1;
        color: var(--mantine-color-gray-6) !important;
    }

    --input-bd-focus: var(--mantine-color-green-2) !important
}

.label {
    font-weight: 500;
}

.dropdown {
    background-color: var(--mantine-color-gray-0) !important;
    border-radius: var(--mantine-radius-md);
    font-weight: 500;
    border: 1px solid var(--mantine-color-gray-7) !important;
    box-shadow: 0 4px 4px 1px rgba(0,0,0,0.11) !important
}

.option {
    padding: 0;
}

.option:where([data-combobox-selected]) {
    background-color: var(--mantine-color-gray-7) !important;
    color: var(--mantine-color-textBlack-4) !important;
}

/* Using the same css class used in Mantine and overriding the background-color */
@media (hover: hover) {
    :where([data-mantine-color-scheme='light']) .option:hover:where(:not([data-combobox-selected], [data-combobox-disabled])) {
        background-color: var(--mantine-color-green-2) !important;
    }

    :where([data-mantine-color-scheme='dark']) .option:hover:where(:not([data-combobox-selected], [data-combobox-disabled])) {
        background-color: var(--mantine-color-green-2) !important;
    }
}