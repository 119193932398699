
.root {
    height: 100%;
}

.tabList {
    --tabs-color: var(--mantine-color-green-2);
    color: var(--mantine-colors-textBlack-4);
    background-color: var(--mantine-color-gray-5);
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
    padding: var(--mantine-spacing-sm);
    [data-active] {
        color: var(--mantine-colors-textBlack-4);
    }
}

.panel {
    height: 85%;
}

.list {
    height: 15%;
}