


.searchInputTextInput {
    border: 1px solid var(--mantine-color-gray-2) !important;

}

.searchInputTextInput::placeholder {
    color: var(--mantine-color-gray-3) !important
}

.tableHeader {
    color: var(--mantine-color-textBlack-2);
    font-weight: normal !important;
    font-family: 'Poppins-light', sans-serif;
    font-size: var(--mantine-font-size-xs);

    th:first-child {
        padding-left: var(--mantine-spacing-md);
    }

    th:last-child {
        padding-right: var(--mantine-spacing-md);
    }

    th:last-child > div > div {
        justify-content: flex-end;
    }

    th {
        padding-top: var(--mantine-spacing-sm);
        padding-bottom: var(--mantine-spacing-sm)
    }
}

.tableTable {

    tr td:first-child {
        padding-left: var(--mantine-spacing-md)
    }

    tr td:last-child {
        padding-right: var(--mantine-spacing-md)
    }

    tr td {
        padding-top: var(--mantine-spacing-md);
        padding-bottom: var(--mantine-spacing-md)
    }
}


.filterDropdown {
    width: 230px !important;
}