


.textInput {
    .textInputRoot {

    }


}

.formInput {
    border-color: var(--mantine-color-gray-2) !important;
}

.formInput::placeholder {
    color: var(--mantine-color-gray-3) !important
}


.buttonRoot {

    &:hover {
        background-color: var(--mantine-color-gray-5);
    }

    .accessText {
        font-size: var(--mantine-font-size-sm)
    }
}